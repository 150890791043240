<template>
  <div class="container">
    <img 
      v-if="$root.app == 'SoftProCrmAppRioTransTest' || $root.app == 'SoftProCrmAppRioTrans'"
      src="img/logo-blue.svg" alt="">
    <h1>{{$root.langText.login}}</h1>
    <form @submit.prevent="login">
      <input type="text" placeholder="Логин"
        v-model.trim="email" 
        :class="{'invalid': $v.email.$dirty && !$v.email.required}"
      >
      <div class="login__password">
        <input placeholder="Пароль"
          :type="passwordType"
          v-model.trim="password" 
          :class="{'invalid': ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)}"
        >
        <a @click.prevent="passwordType = passwordType == 'password' ? 'text' : 'password'" class="icon icon-eye"></a>
      </div>
      <div class="login__btn">
        <button class="btn btn-primay btn btn-primary" type="submit">{{$root.langText.comeIn}}</button>
        <!-- <router-link to="/password-recovery" class="login__forgot">Забули пароль?</router-link> -->
      </div>
    </form>
    <Message v-if="message.popup" :message="message" @close="message.popup = false"/>
  </div>
</template>



<style lang="scss">
  @import '../assets/login.css';
  .dark .login input{
    color: #c3dbf6;
  }
</style>



<script>
import {required, minLength} from 'vuelidate/lib/validators'
import Message from "@/components/app/Message"


export default {
  name: 'Login',
  data: () => ({
    email: '',
    password: '',
    passwordType: 'password',

    //message
    message: {
      status: '',
      popup: false,
      message: ''
    }
  }),



  validations: {
    email: {required},
    password: {required, minLength: minLength(1)}
  },



  methods: {
    async login() {
      if(this.$v.$invalid){
        this.$v.$touch()
        return
      }
      await this.$store.dispatch('login', { 
        login: this.email,
        password: this.password 
      })
      .then(resp => {
        this.$router.push('/')
      })
      .catch(err => {
        //messedge
        // console.log(err)
        if(err == 'user not found'){
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.setting.noUser
        } else if(err == 'wrong password'){
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.setting.errorPassword
        }
      })
      
    },
  },


  components: {
    Message
  },



  metaInfo(){
    return {
      title: this.$title(this.$root.langText.login)
    }
  },
}
</script>
